import Image from "@/components/image";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { addToCart, isInCart } from "@/store/cart-store";
import type { Product, ProductVariant } from "@/types";
import { Plus, ShoppingBasket } from "lucide-react";
import { useState, type PropsWithChildren } from "react";
import { RiShoppingBag3Fill } from "react-icons/ri";
import { TbShoppingCartOff } from "react-icons/tb";
import { toast } from "sonner";

interface Props extends PropsWithChildren {
  product: Product;
}

const AddToCartButton = ({ product, children }: Props) => {
  const [alreadyInCart, setAlreadyInCart] = useState(isInCart(product));
  const isOutOfStock = product.available <= 0;
  const [open, setOpen] = useState(false);
  const [savedCounter, setSavedCounter] = useState(0);

  const handleAddtoCart = () => {
    if (product.has_variants) {
      setOpen(true);
      return;
    }

    if (alreadyInCart || isInCart(product)) {
      setAlreadyInCart(true);
    }

    if (isOutOfStock) {
      toast.error("Product is out-of-stock!");
      return;
    }

    addToCart({
      product,
      quantity: 1,
      price: product.price,
      discount: 0,
      tax: 0,
    });

    setAlreadyInCart(true);
  };

  const handleAddVariantToCart = (variant: ProductVariant) => {
    if (variant.available <= 0) {
      toast.info("Out-of-stock!");
      return;
    }

    addToCart({
      product,
      variant,
      quantity: 1,
      price: product.price,
      discount: 0,
      tax: 0,
    });

    setAlreadyInCart(true);
    setSavedCounter(savedCounter + 1);
  };

  return (
    <div>
      <button
        onClick={handleAddtoCart}
        type="button"
        className={cn(
          "w-full sm:w-auto px-3 py-2 sm:px-4 sm:py-2 bg-kp-black text-kp-white rounded-lg text-xs sm:text-sm font-medium hover:bg-kp-accent hover:text-kp-black transition-colors",
          {
            "opacity-50 cursor-not-allowed": isOutOfStock,
          }
        )}>
        {alreadyInCart ? (
          <RiShoppingBag3Fill className="w-4 h-4 mr-2 inline-block" />
        ) : (
          <ShoppingBasket className="w-4 h-4 mr-2 inline-block" />
        )}
        {isOutOfStock ? "Out of Stock" : "Add to Cart"}
      </button>

      {/* select variant */}
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-lg lg:max-w-3xl">
          <DialogHeader>
            <DialogTitle>Select a Variant</DialogTitle>
            <DialogDescription>
              {product.variants?.length} variants
            </DialogDescription>
          </DialogHeader>

          <ScrollArea
            className="w-full max-h-[70vh]"
            key={`var-counter-${savedCounter}`}>
            <div className="flex flex-wrap items-baseline gap-5">
              {product.variants?.map((variant) => {
                return (
                  <button
                    key={variant.id}
                    data-id={variant.id}
                    onClick={() => handleAddVariantToCart(variant)}
                    className="cursor-pointer group transition space-y-2 border-b-2 border-transparent hover:border-primary/50 rounded-b-md overflow-hidden w-[110px]">
                    <Image
                      src={variant?.featured_img || product.featured_img}
                      alt={product.title}
                      className="w-full h-[80px]"
                    />

                    <div className="flex gap-1 items-baseline justify-center">
                      {isInCart(product, variant) && (
                        <RiShoppingBag3Fill className="w-4 h-4" />
                      )}
                      <h4 className="text-sm font-medium group-hover:text-primary transition">
                        <span className="mr-1">{variant.display_name}</span>
                        {variant.available > 0 ? (
                          <span>({variant.available})</span>
                        ) : (
                          <span>
                            <TbShoppingCartOff className="inline-block w-3 h-3 text-red-400" />
                          </span>
                        )}
                      </h4>
                    </div>
                  </button>
                );
              })}
            </div>
          </ScrollArea>

          <DialogFooter>
            <Button type="button" onClick={() => setOpen(false)}>
              <Plus className="w-4 h-4 inline-block mr-2" />
              Add
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddToCartButton;
