import { defaultImageUrl } from "@/lib/utils";
import type { Product } from "@/types";
import { formatNumber } from "@acelords/js-utils";
import { Heart } from "lucide-react";
import AddToCartButton from "./_add-to-cart-button";

interface Props {
  product: Product;
}

const ProductCard = ({ product }: Props) => {
  return (
    <div className="bg-kp-white rounded-xl shadow-lg overflow-hidden">
      <div className="relative aspect-square bg-[#FDFDFD]">
        <span className="absolute top-2 left-2 sm:top-4 sm:left-4 bg-kp-accent text-kp-black px-2 py-1 text-xs sm:text-sm font-medium rounded-md z-10">
          NEW
        </span>
        <button
          title="Add to favorites"
          className="absolute top-2 right-2 sm:top-4 sm:right-4 p-1 sm:p-2 bg-kp-accent text-kp-black rounded-full shadow-md hover:bg-kp-black hover:text-kp-accent transition-colors transform hover:scale-110">
          <Heart className="w-4 h-4 sm:w-5 sm:h-5" />
        </button>
        <img
          src={product.featured_img || defaultImageUrl}
          alt={product.title}
          className="w-full h-full object-contain p-2 sm:p-4 transition-transform duration-300 hover:scale-105"
        />
      </div>

      <div className="p-3 sm:p-6">
        <div className="flex items-center gap-1 sm:gap-2 mb-1 sm:mb-2">
          <div className="flex">
            {Array.from({ length: 5 }).map((_, i) => (
              <svg
                key={i}
                className={`w-3 h-3 sm:w-4 sm:h-4 ${i < Math.floor(product.rating ?? 4.5) ? "text-yellow-400" : "text-gray-300"}`}
                fill="currentColor"
                viewBox="0 0 20 20">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            ))}
          </div>
          <span className="text-xs sm:text-sm text-kp-secondary">
            ({product.sold_counter ?? "48"})
          </span>
        </div>

        <h3 className="text-sm sm:text-lg font-semibold mb-2 sm:mb-4">
          {product.title}
        </h3>

        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <span className="text-base sm:text-xl font-bold text-kp-black mb-2 sm:mb-0">
            {product.currency.code} {formatNumber(product.price)}
          </span>
          <AddToCartButton product={product} />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
